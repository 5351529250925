import '../css/myaccount.scss';

(function ($) {
    $(document).ready(function () {
        // Check if we're on the registration page
        if ($('.woocommerce-form-register').length === 0) {
            return;
        }

        function handleNewsletterSignup(email) {
            return new Promise((resolve, reject) => {
                const timeoutDuration = 10000; // 10 seconds
                const timeout = setTimeout(() => {
                    reject('Newsletter signup timed out');
                }, timeoutDuration);
                $.ajax({
                    type: 'POST',
                    url: '/wp-content/themes/storefront-child/mailchimp-handler-register.php',
                    data: { EMAIL: email },
                    dataType: 'json',
                    success: function (response) {
                        clearTimeout(timeout);
                        console.log('Newsletter signup response:', response);
                        if (response && response.success) {
                            resolve(response.message);
                        } else {
                            reject(response.message || 'Unknown error occurred');
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        clearTimeout(timeout);
                        console.error('Newsletter signup error:', textStatus, errorThrown);
                        reject('Error during newsletter signup: ' + textStatus);
                    }
                });
            });
        }

        function verifyRecaptcha(token) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: '/wp-content/themes/storefront-child/recaptcha-verify.php',
                    data: { 'recaptcha-token': token },
                    dataType: 'json',
                    success: function (response) {
                        if (response.success) {
                            resolve();
                        } else {
                            reject(response.message);
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        reject('Error verifying reCAPTCHA: ' + textStatus);
                    }
                });
            });
        }

        $('.woocommerce-form-register__submit').on('click', function (e) {
            e.preventDefault();

            var $button = $(this);
            var $form = $button.closest('form');
            var $messageDiv = $form.find('.sky-reg-popup-form-message');
            var email = $form.find('#reg_email').val();
            var isNewsletterChecked = $('#newsletter_signup').is(':checked');
            var originalButtonText = $button.text();

            $button.text('Processing...').prop('disabled', true);
            $messageDiv.empty();

            function completeRegistration(message = '') {
                $button.text('Completing registration...');
                setTimeout(() => {
                    submitFormViaAjax($form, $messageDiv, $button, originalButtonText);
                }, 500);
            }

            // Set a global timeout for the entire process
            const globalTimeout = setTimeout(() => {
                console.error('Registration process timed out');
                handleError($messageDiv, $button, originalButtonText, 'Registration process timed out. Please try again.');
            }, 15000); // 15 seconds

            // Execute reCAPTCHA
            grecaptcha.ready(function () {
                grecaptcha.execute('6LcfHKgbAAAAANkB1eVX-gpi5TlIwfj2hP9FCIiN', { action: 'submit' }).then(function (token) {
                    // Verify reCAPTCHA
                    verifyRecaptcha(token).then(() => {
                        // reCAPTCHA verification successful, proceed with existing process
                        if (isNewsletterChecked) {
                            $button.text('Signing up for newsletter...');
                            handleNewsletterSignup(email)
                                .then((message) => {
                                    $messageDiv.html('<p class="alert alert-success">' + message + '</p>');
                                    $button.text('Newsletter signup complete...');
                                })
                                .catch((error) => {
                                    console.error('Newsletter signup error:', error);
                                    $messageDiv.html('<p class="alert alert-warning">Newsletter signup failed: ' + error + '</p>');
                                    $button.text('Newsletter signup failed...');
                                })
                                .finally(() => {
                                    clearTimeout(globalTimeout);
                                    completeRegistration('Proceeding with user registration');
                                });
                        } else {
                            clearTimeout(globalTimeout);
                            completeRegistration('Proceeding with user registration');
                        }
                    }).catch((error) => {
                        clearTimeout(globalTimeout);
                        handleRecaptchaError($messageDiv, $button, originalButtonText, error);
                    });
                });
            });
        });

        function submitFormViaAjax($form, $messageDiv, $button, originalButtonText) {
            var formData = new FormData($form[0]);
            formData.append('action', 'sky_register_user');

            $.ajax({
                url: woocommerce_params.ajax_url,
                type: 'POST',
                data: formData,
                processData: false,
                contentType: false,
                success: function (response) {
                    if (response.success) {
                        $messageDiv.html('<p class="alert alert-success">' + response.data.message + '</p>');
                        setTimeout(function () {
                            window.location.href = response.data.redirect;
                        }, 2000);
                    } else {
                        handleError($messageDiv, $button, originalButtonText, response.data.message || 'An error occurred during registration.');
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.error('Registration error:', textStatus, errorThrown);
                    handleError($messageDiv, $button, originalButtonText, 'An error occurred during registration. Please try again.');
                }
            });
        }

        function handleError($messageDiv, $button, originalButtonText, errorMessage) {
            $messageDiv.html('<p class="alert alert-warning">' + errorMessage + '</p>');
            $button.text(originalButtonText).prop('disabled', false);
        }

        function handleRecaptchaError($messageDiv, $button, originalButtonText, errorMessage) {
            console.error('reCAPTCHA verification failed:', errorMessage);
            let userMessage = 'We couldn\'t verify your request. Please try again.';
            
            if (errorMessage.includes('Suspected unusual traffic')) {
                userMessage = 'We detected unusual traffic from your network. Please try again later or from a different network.';
            } else if (errorMessage.includes('Error occurred while verifying')) {
                userMessage = 'We couldn\'t verify your request. Please try again.';
            } else if (errorMessage.includes('Invalid request')) {
                userMessage = 'There was a problem with your request. Please refresh the page and try again.';
            }

            $messageDiv.html('<p class="alert alert-warning">' + userMessage + '</p>');
            $button.text(originalButtonText).prop('disabled', false);
        }
    });
})(jQuery);